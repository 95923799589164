<template>
  <div id="home">

    <div class="content">
      <div class="title">
        Payment History
      </div>
      <div class="center">
        <el-date-picker style="width: 300px;"
          v-model="value"
          type="daterange"
          :clearable="false"
          @change="changeTime"
          format="MM/dd/yyyy"
          value-format="yyyy-MM-dd"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          placement="bottom-start">
        </el-date-picker>
        <i class="el-icon-arrow-down" style="color:gray;position: absolute;margin-left: -30px;margin-top: 12px;"></i>
      </div>
      <el-table
        v-loading="table_loading"
        :data="tableData"
        @row-click="rowClick"
        :header-cell-style="{ fontSize: '14px', backgroundColor: '#F8F9FA',color:'#4A5468',fontWeight:'normal'}"
        :cell-style="{ fontSize: '14px'}"
        style="width: 100%;">
        <el-table-column
          align="center"
          min-width="120"
          label="Invoice #">
          <template slot-scope="scope">
            DITA-{{auto_complete_placeholder(5,scope.row.invoice_number)}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="180"
          label="Date">
          <template slot-scope="scope">
            {{moment(scope.row.invoice_date).format("MM-DD-YYYY")}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="150"
          label="Status">
          <template slot-scope="scope">
            <span v-if="scope.row.invoice_status == 'OVERDUE'" style="color: #E53E3E;">{{translate_status(scope.row.invoice_status)}}</span>
            <span v-else-if="scope.row.invoice_status == 'PAID'" style="color: #34C759;">{{translate_status(scope.row.invoice_status)}}</span>
            <span v-else>{{translate_status(scope.row.invoice_status)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="150"
          label="Due By">
          <template slot-scope="scope">
            {{moment(scope.row.due_date).format("MM-DD-YYYY")}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="150"
          label="Total">
          <template slot-scope="scope">
            ${{scope.row.total_amounts}}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          min-width="150"
          label="Balance">
          <template slot-scope="scope">
            ${{scope.row.balance_amounts}}
          </template>
        </el-table-column>

        <el-table-column
          align="center"
          min-width="200"
          label="Paid Date">
          <template slot-scope="scope">
            {{scope.row.paid_datetime?moment(scope.row.paid_datetime).format("MM/DD/YYYY HH:mm A"):'-'}}
          </template>
        </el-table-column>

        <el-table-column
          label="Action"
          align="center"
          min-width="100">
          <template slot-scope="scope">
            <el-link type="primary" title="Detail" @click.stop="rowClick(scope.row)"><i class="font-size-16 el-icon-search"></i></el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="page_size"
          :total="totalRows">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  export default {
    name: 'PaymentHistory',
    components: {
    },
    data() {
      return {
        value:[],
        totalRows: 0,
        current_page: 1,
        page_size: 10,
        tableData: [],
        table_loading: false,
        status_map: {"OPEN":"Open", "OVERDUE":"Overdue",
        "PAID": "Paid", "CANCELED":"Canceled", "PARTIAL": "Partial"},
      }
    },
    computed: {

    },
    mounted() {

    },
    created(){
      this.getDatas(1)
    },
    methods:{
      moment,
      translate_status(status) {
        if(status) {
          return this.status_map[status]
        }
        return;
      },
      auto_complete_placeholder(digit, number) {
        if(digit > number.toString().length) {
          return (this.completion_str((digit - number.toString().length), "0")) + number
        }
        return number.toString();
      },
      completion_str(digit, target) {
        let str = "";
        for(let i = 0;i < digit; i++) {
          str += target
        }
        return str;
      },
      rowClick(row){
        if(row.invoice_status == 'OVERDUE' || row.invoice_status == 'OPEN') {
          this.$router.push({
            path: '/bills-pay',
            query: {
              uuid: row.client_invoice_uuid
            }
          });
        } else {
          this.$router.push({
            path: '/billsDetail',
            query: {
              uuid: row.client_invoice_uuid
            }
          });
        }
      },
      changeTime(e){
        this.getDatas(1)
      },
      getDatas(initSize) {
        if(initSize == 1) {
          this.current_page = 1
        } else {
          this.current_page = initSize
        }
        this.table_loading = true

        let param = {};
        if(this.value.length > 0) {
          param = {start_date:this.value[0],end_date:this.value[1]}
        }
        getActionApi("/payment/invoices?size=" + this.page_size + "&page=" + this.current_page,param).then((res) => {
          this.tableData = res.data
          this.totalRows = res.page.totalElements
        }).catch((err) => {

        }).finally((res) => {
          this.table_loading = false
        })
      },
      handleCurrentChange(val) {
        this.getDatas(val)
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;word-break:normal;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .content{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;align-items: center;}
  .title div *{padding:10px 16px;font-size: 14px;}
  .pager{display: flex;justify-content: center;}
  .font-size-16 {font-size: 20px;}
  .margin-top-switch {margin-top: 10px;}

  .avatar-uploader-icon {font-size: 18px;color: #8c939d;}
  .headImageShow {width: 76px;height: 76px;border-radius: 4px;}
  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .title div{margin-top:20px;}
    .center{display: flex;justify-content: center;}
    .content{padding:20px 5px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
