<template>
  <div>
    <el-dialog title="Scan Detail" :width="dialogWidth" :visible="visible" :close-on-click-modal="false" @close="visible = false">
      <div class="form" v-loading="form_loading">
        <div class="div">
          <div class="flexSpace">
            <h1></h1>
            <div>
              Deep Scan
              <el-switch
                style="margin-left:10px;"
                disabled
                v-model="grid_type"
                active-color="#5856D6"
                inactive-color="#ededed">
              </el-switch>
            </div>
          </div>
          <div class="grid" v-if="info.main">
            <div class="flex">
              <h2>Keyword</h2>
              <span>{{info.main.keyword}}</span>
            </div>
            <div class="flex">
              <h2>Grid</h2>
              <span>{{parseInt(Math.sqrt(info.cells.length))}} × {{parseInt(Math.sqrt(info.cells.length))}}</span>
            </div>
            <div class="flex">
              <h2>Diagonal</h2>
              <span>{{info.main.diagonal_km}} mile</span>
            </div>
            <div class="flex">
              <h2>Competitors</h2>
              <span>
                <p v-for="item,index in cList" :key="index">{{item.name}}</p>
              </span>
            </div>
          </div>
        </div>
        <div class="div mapDiv" style="margin-top: 20px;">
          <GmapMap
            :center="mapCenter"
            :key="mapKey"
            ref="mapRef"
            :zoom="zoom"
            style="width: 100%; height: 400px;"
          >
            <GmapPolygon
              :paths="boundary"
              :options="{ fillColor: '#5856D6', fillOpacity: 0.2, strokeWeight: 1}"
            />
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :label="''+(m.my_ranking == -1 ? '20+' : m.my_ranking)"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :icon="{
                url:require('../../assets/imgs/icon_loading_comp'+getNum(m.my_ranking)+'.png'),
                scaledSize:{width:34,height:34}
              }"
              @click="openDetail(m)"
            >
            </GmapMarker>
          </GmapMap>
          <div>
            <div class="imgBg" @click="centerMap">
              <img src="../../assets/imgs/backCenter.png"/>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <show-result ref="viewResultForm"></show-result>
  </div>
</template>

<script>
import Vue from 'vue';
import { getActionApi } from '@/network/manageApi';
import ShowResult from './showResult'
import moment from 'moment/moment';
export default {
  name: 'Show',
  components: {ShowResult},
  data() {
    return {
      dialogWidth:'1088px',
      visible: false,
      grid_type:true,
      info: {},
      cList:[],
      mapKey:0,
      competitorList:[],
      gridList:[3,5,7],
      diagonalList:[0.5,1,3,5],
      mapCenter:{lat: 0, lng: 0},
      backCenter:{},
      boundary:[],
      markers: [],
      zoom:12,
      form_loading: false
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  computed: {

  },
  created() {
    this.setDialogWidth()
    getActionApi('/seo/competitor').then(res => {
      this.competitorList = res.data
    }).catch((err) => {
      this.$message.error(err.response.data.details.data.message);
    }).finally((res) => {
    })
  },
  methods: {
    moment,
    getNum(number){
      if(number >= 1 && number <= 3)
        return 1
      else if(number >= 4 && number <= 8)
        return 2
      else if(number >= 9 && number <= 15)
        return 3
      else if(number >= 15 && number <= 19)
        return 4
      else
        return 5
    },
    init(id,cList) {
      this.visible = true
      this.form_loading = true
      this.info = {}
      this.mapKey = 0
      this.mapCenter = {lat: 0, lng: 0}
      this.markers = []
      this.boundary = []
      this.cList = cList != null ? cList : []
      getActionApi('seo/grid-map/'+id).then((res) => {
        this.info = res.data
        if(this.info.main && this.info.main.boundary){
          this.boundary = [
            {lat:this.info.main.boundary.ne.latitude,lng:this.info.main.boundary.ne.longitude},
            {lat:this.info.main.boundary.nw.latitude,lng:this.info.main.boundary.nw.longitude},
            {lat:this.info.main.boundary.sw.latitude,lng:this.info.main.boundary.sw.longitude},
            {lat:this.info.main.boundary.se.latitude,lng:this.info.main.boundary.se.longitude},
            {lat:this.info.main.boundary.ne.latitude,lng:this.info.main.boundary.ne.longitude}
          ]
        }
        this.grid_type = this.info.main.type != 'REGULAR'
        this.$set(this.mapCenter,'lat',this.info.main.center_point.latitude)
        this.$set(this.mapCenter,'lng',this.info.main.center_point.longitude)
        this.backCenter = {...this.mapCenter}
        let cells = this.info.cells
        for(let i = 0; i < cells.length; i++){
          cells[i].position = {lat:cells[i].cell_center_point.latitude,lng:cells[i].cell_center_point.longitude}
        }
        this.markers = cells
        this.zoom = 12
        if(this.info.main.diagonal_km == 0.5)
          this.zoom = 15
        else if(this.info.main.diagonal_km == 1)
          this.zoom = 14
        else if(this.info.main.diagonal_km == 3)
          this.zoom = 13
        else if(this.info.main.diagonal_km == 5)
          this.zoom = 12
        else if(this.info.main.diagonal_km == 10)
          this.zoom = 11
        else if(this.info.main.diagonal_km == 20)
          this.zoom = 10
        else if(this.info.main.diagonal_km == 30)
          this.zoom = 9
        this.mapKey ++
      }).catch((err) => {
        this.$message.error(err.response.data.details.data.message);
      }).finally((res) => {
        this.form_loading = false
      })
    },
    openDetail(detail){
      let type = this.info.main.type == 'DEEPSCAN'
      this.$refs.viewResultForm.init(detail,type)
    },
    centerMap() {
      this.$refs.mapRef.panTo(this.backCenter);
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 1088 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  *{word-break:normal;}
  .form{}
  .div{background-color: #ffffff;display: grid;gap: 20px;border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;flex-direction: column;font-size: 16px;}
  .title div .span{color:#5856D6;font-size: 16px;}
  .grid{display: grid;grid-template-columns: 1fr 1fr 1fr 2fr;grid-gap: 10px;}
  .grid .flex{display: flex;flex-direction: column;}
  .grid .flex h2{line-height: 50px;display: flex;justify-content: space-between;align-items: center;color: #A0ABC0;}
  .flexSpace{display: flex;justify-content: space-between;align-items: center;}
  .flexSpace h1{color:#2D3648;font-size: 20px;line-height: 1;}
  .flexSpace div{color:#A0ABC0;}
  .mapDiv{display: flex;align-items: flex-end;}
  .imgBg{position: absolute;width:40px;height:40px;margin-top:-160px;margin-left:-70px;background-color: #ffffff;display: flex;justify-content: center;align-items: center;box-shadow: 0 0 2px 1.5px rgba(0, 0, 0, 0.1);cursor: pointer;}
  .mapDiv img{width: 20px;}
  ::v-deep .gm-style .gm-bundled-control-on-bottom .gm-style-mtc label {
    color: #ffffff !important;
  }
  @media (max-width: 768px) {
     .form{display: grid;grid-template-columns:1fr;}
     .left{border-right: 0px solid #EDF2F7;padding-right:0;margin-right: 0;}
     .grid{grid-template-columns: 1fr;}
  }

</style>
