<template>
  <div id="home">
    <div class="div" v-loading="loadingMain">
      <div class="flexSpace">
        <h1>Map Scan</h1>
        <!-- <div>
          Deep Scan
          <el-switch
            style="margin-left:10px;"
            v-model="grid_type"
            active-color="#5856D6"
            inactive-color="#ededed">
          </el-switch>
        </div> -->
      </div>
      <div class="grid">
        <div class="flex">
          <h2>Keyword</h2>
          <el-select
              v-model="keyword"
              :multiple="false"
              :clearable="true"
              :allow-create="true"
              filterable
              remote
              reserve-keyword
              placeholder="Search Keyword"
              :remote-method="remoteMethod"
              :loading="keywordsLoading">
              <el-option
                v-for="item in keywords"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </div>
        <div class="flex">
          <h2>Grid</h2>
          <el-select v-model="grid">
            <el-option
              v-for="item in gridList"
              :key="item"
              :label="item + ' × ' + item"
              :value="item + ' × ' + item">
            </el-option>
          </el-select>
        </div>
        <div class="flex">
          <h2>Diagonal</h2>
          <el-select v-model="diagonal">
            <el-option
              v-for="item in diagonalList"
              :key="item"
              :label="item + ' mile'"
              :value="item + ' mile'">
            </el-option>
          </el-select>
        </div>
        <div class="flex">
          <h2>Competitors<el-button size="mini" style="background-color:#5856D6;color:#ffffff;" @click="showCompetitorsList">manage</el-button></h2>
          <el-select v-model="competitors" multiple collapse-tags>
            <el-option
              v-for="item in competitorList"

              :key="item.client_competitors_id"
              :label="item.name"
              :value="item.client_competitors_id">
            </el-option>
          </el-select>
        </div>
        <div class="flex">
          <el-button style="background-color:#5856D6;color:#ffffff;" @click="scanFunction">Scan</el-button>
        </div>
      </div>
    </div>
    <div class="div mapDiv" v-loading="mapLoading">
      <GmapMap
        :center="mapCenter"
        :zoom="zoom"
        :key="mapKey"
        ref="mapRef"
        style="width: 100%; height: 450px;"
      >
        <GmapPolygon
          :paths="boundary"
          :options="{ fillColor: '#5856D6', fillOpacity: 0.2, strokeWeight: 1}"
        />
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :label="''+((m.resultInfo&&m.resultInfo.my_ranking)?(m.resultInfo.my_ranking == -1 ? '20+' : m.resultInfo.my_ranking):'')"
          :clickable="true"
          :draggable="false"
          @click="openDetail(m.resultInfo)"
          :icon="{
            url:!m.complete?require('../assets/imgs/icon_loading.gif'):require('../assets/imgs/icon_loading_comp'+getNum(m.resultInfo.my_ranking)+'.png'),
            scaledSize:{width:34,height:34}
          }"
        />
      </GmapMap>
      <div>
        <div class="imgBg" @click="centerMap">
          <img src="../assets/imgs/backCenter.png"/>
        </div>
      </div>
    </div>
    <competitors-list ref="listForm" @ok="loadCompetitors"></competitors-list>
    <show-result ref="viewResultForm"></show-result>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi,postActionApi,putActionApi } from '@/network/manageApi';
  import CompetitorsList from './competitors/list'
  import ShowResult from './mapScan/showResultScan'
  import moment from 'moment/moment';
  export default {
    name: 'MapScan',
    components: {
      CompetitorsList,ShowResult
    },
    data() {
      return {
        loadingMain:false,
        mapLoading:false,
        competitorLoading:false,
        keywordsLoading:false,
        grid_type:true,
        competitorList:[],
        keywords:[],
        gridList:[3,5,7],
        diagonalList:[0.5,1,3,5,10,20,30],
        keyword:'',
        grid:'5 × 5',
        zoom:12,
        client_map_grid_uuid:'',
        mapKey:0,
        diagonal:'5 mile',
        competitors:'',
        mapCenter:{lat: 0, lng: 0},
        backCenter:{},
        markers: [],
        boundary:[],
      }
    },
    computed: {

    },
    mounted() {
    },
    created(){
      this.mapLoading = true
      getActionApi('/user-management/my-information/googleplace').then(res => {
        this.$set(this.mapCenter,'lat',res.data.location.latitude)
        this.$set(this.mapCenter,'lng',res.data.location.longitude)
        this.backCenter = {...this.mapCenter}
      }).catch((err) => {
        this.$message.error(err.response.data.details.data.message);
      }).finally((res) => {
        this.mapLoading = false
      })
      this.loadCompetitors()
    },
    methods:{
      getNum(number){
        if(number >= 1 && number <= 3)
          return 1
        else if(number >= 4 && number <= 8)
          return 2
        else if(number >= 9 && number <= 15)
          return 3
        else if(number >= 15 && number <= 19)
          return 4
        else
          return 5
      },
      loadCompetitors(){
        this.competitorLoading = true
        getActionApi('/seo/competitor').then(res => {
          this.competitorList = res.data
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.competitorLoading = false
        })
      },
      showCompetitorsList(){
        this.$refs.listForm.init()
      },
      openDetail(detail){
        this.$refs.viewResultForm.init(detail,this.grid_type)
      },
      scanFunction(){
        if(!this.keyword){
          this.$message.error('Keyword Required!');
        }else if(!this.grid){
          this.$message.error('Grid Required!');
        }else if(!this.diagonal){
          this.$message.error('Diagonal Required!');
        }else{
          const param = {keyword:this.keyword}
          param.grid = parseInt(this.grid.substring(0,1))
          param.diagonal = parseFloat(this.diagonal.substring(0,this.diagonal.indexOf(' ')))
          param.grid_type = this.grid_type?'DEEPSCAN':'REGULAR'
          this.loadingMain = true
          postActionApi('/seo/grid-map/initialization',param).then(res => {
            this.$set(this.mapCenter,'lat',res.data.center_point.latitude)
            this.$set(this.mapCenter,'lng',res.data.center_point.longitude)
            this.client_map_grid_uuid = res.data.client_map_grid_uuid
            this.boundary = [
              {lat:res.data.boundary_ne.latitude,lng:res.data.boundary_ne.longitude},
              {lat:res.data.boundary_nw.latitude,lng:res.data.boundary_nw.longitude},
              {lat:res.data.boundary_sw.latitude,lng:res.data.boundary_sw.longitude},
              {lat:res.data.boundary_se.latitude,lng:res.data.boundary_se.longitude},
              {lat:res.data.boundary_ne.latitude,lng:res.data.boundary_ne.longitude}
            ]
            let cells = res.data.circles
            for(let i = 0; i < cells.length; i++){
              cells[i].position = {lat:cells[i].cell_center.latitude,lng:cells[i].cell_center.longitude}
              cells[i].complete = false
            }
            this.markers = cells
            this.zoom = 12
            if(param.diagonal == 0.5)
              this.zoom = 15
            else if(param.diagonal == 1)
              this.zoom = 14
            else if(param.diagonal == 3)
              this.zoom = 13
            else if(param.diagonal == 5)
              this.zoom = 12
            else if(param.diagonal == 10)
              this.zoom = 11
            else if(param.diagonal == 20)
              this.zoom = 10
            else if(param.diagonal == 30)
              this.zoom = 9
            this.mapKey ++
            cells.forEach((cell, i) => {
              this.scanFunctionDetail(cell.client_map_grid_cell_uuid,i)
            })
          }).catch((err) => {
            this.$message.error(err.response.data.details.data.message);
            this.loading = false
          }).finally((res) => {

          })
        }
      },
      scanFunctionDetail(id,index){
        const param = {
          client_map_grid_uuid:this.client_map_grid_uuid,
          client_map_grid_cell_uuid:id,
          client_competitors_id:[]
        }
        if(this.competitors && this.competitors.length > 0)
          param.client_competitors_id = this.competitors
        postActionApi('/seo/grid-map/scan',param).then(res => {
          this.$set(this.markers[index],'complete',true)
          let compCount = 0
          for(let i = 0; i < this.markers.length; i++){
            if(this.markers[i].complete)
              compCount ++
          }
          this.$set(this.markers[index],'resultInfo',res.data)
          if(compCount == this.markers.length){
            putActionApi('/seo/grid-map/complete/'+this.client_map_grid_uuid).then(res => {
              this.$message({
                type: 'success',
                message: 'scan success!'
              });
            }).catch((err) => {
              this.$message.error(err.response.data.details.data.message);
            }).finally((res) => {
              this.loadingMain = false
            })
          }
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {

        })
      },
      centerMap() {
        this.$refs.mapRef.panTo(this.backCenter);
      },
      remoteMethod(query) {
        if (query !== '') {
          this.keywordsLoading = true;
          setTimeout(() => {
            this.keywordsLoading = false;
            getActionApi('/seo/keyword/autocomplete?query='+query).then(res => {
              this.keywords = res.data
            }).catch((err) => {
              this.$message.error(err.response.data.details.data.message);
            }).finally((res) => {
              this.competitorLoading = false
            })
          }, 200);
        } else {
          this.keywords = [];
        }
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: grid;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;flex-direction: column;font-size: 16px;}
  .title div .span{color:#5856D6;font-size: 16px;}
  .grid{display: grid;grid-template-columns: 1fr 1fr 1fr 2fr 100px;grid-gap: 10px;}
  .grid .flex{display: flex;flex-direction: column;justify-content: flex-end;}
  .grid .flex h2{line-height: 50px;display: flex;justify-content: space-between;align-items: center;color: #A0ABC0;}
  .flexSpace{display: flex;justify-content: space-between;align-items: center;}
  .flexSpace h1{color:#2D3648;font-size: 20px;line-height: 1;}
  .flexSpace div{color:#A0ABC0;}
  .mapDiv{display: flex;align-items: flex-end;}
  .imgBg{position: absolute;width:40px;height:40px;margin-top:-80px;border-radius:50%;margin-left:-50px;background-color: #ffffff;display: flex;justify-content: center;align-items: center;box-shadow: 0 0 2px 1.5px rgba(0, 0, 0, 0.1);cursor: pointer;}
  .mapDiv img{width: 20px;}
  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .div{padding:20px 5px;}
    .grid{grid-template-columns: 1fr;padding:20px;}
    .flexSpace{padding:0 20px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
