<template>
  <div id="home">
    <div class="div">
      <div class="title">
        Monthly Analytics
      </div>
      <div class="time">
        <span @click="initList(-1)"><i class="el-icon-arrow-left"></i></span>
        {{year}}
        <span @click="initList(1)"><i class="el-icon-arrow-right"></i></span>
      </div>
      <div class="grid" v-loading="loading">
        <div id="basic_dashboard1"></div>
        <div id="basic_dashboard2"></div>
        <div id="basic_dashboard3"></div>
        <div id="basic_dashboard4"></div>
        <div id="basic_dashboard5"></div>
      </div>

    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import * as echarts from 'echarts';
  import moment from 'moment/moment';
  import { getActionApi } from '@/network/manageApi';
  export default {
    name: 'MonthlyAnalytics',
    components: {

    },
    data() {
      return {
        loading:false,
        year:0,
        myChart:null,
        socialMediaReach:[],
        callCounts:[],
        websiteClick:[],
        fullNameMonth:["January","February","March","April","May","June","July","August","September","October","November","December"],
        abbrNameMonth:['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        weekList:['MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY'],
        initializedData: [0,0,0,0,0,0,0,0,0,0,0,0]
      }
    },
    computed: {

    },
    mounted(){
      this.year = moment().format('YYYY')
      this.initList(0)
      window.addEventListener('resize', this.handleResize);
    },
    created(){

    },
    methods:{
      handleResize() {
        if (this.myChart) {
          this.myChart.resize();
        }
      },
      initList(ind){
        this.year = parseInt(this.year) + ind
        this.socialMediaReach = []
        this.callCounts = []
        this.websiteClick = []
        this.loading = true
        getActionApi('/report/monthly-anlytics/'+this.year).then(res => {
          if(res.code == 200){
            //读取Reach 数据的最大月份
            let facebookReach = res.data.facebook_reach
            let instagramReach = res.data.instagram_reach
            if(facebookReach.length > 0 || instagramReach.length > 0) {
              facebookReach.sort((a, b) => {
                return b.month - a.month; // order desc
              })
              instagramReach.sort((a, b) => {
                return b.month - a.month; // order desc
              })
              let max_month_reach = facebookReach[0]?facebookReach[0].month:0
              let other_month = instagramReach[0]?instagramReach[0].month:0
              if(other_month > max_month_reach) {
                max_month_reach = other_month
              }
              //生成Reach 月份建成data & 月份全称data
              let fullNameMonth_reach = this.fullNameMonth.slice(0, max_month_reach)
              let abbrNameMonth_reach = this.abbrNameMonth.slice(0, max_month_reach)
              this.socialMediaReach = this.initializedData.slice(0, max_month_reach)
              //生成Reach 数据
              for(let i = 0; i < facebookReach.length; i++) {
                this.$set(this.socialMediaReach,facebookReach[i].month - 1,this.socialMediaReach[facebookReach[i].month - 1] + facebookReach[i].reach);
              }
              for(let i = 0; i < instagramReach.length; i++){
                this.$set(this.socialMediaReach,instagramReach[i].month - 1,this.socialMediaReach[instagramReach[i].month - 1] + instagramReach[i].reach);
              }
              this.initChart('basic_dashboard1','Social Media Reach',this.socialMediaReach, fullNameMonth_reach, abbrNameMonth_reach)
            } else {
              this.initChart('basic_dashboard1','Social Media Reach',[], this.fullNameMonth, this.abbrNameMonth)
            }

            let websiteClick = res.data.website_contact
            if(websiteClick.length > 0) {
              websiteClick.sort((a, b) => {
                return b.month - a.month; // order desc
              })
              let max_month_click = websiteClick[0].month
              let fullNameMonth_click = this.fullNameMonth.slice(0, max_month_click)
              let abbrNameMonth_click = this.abbrNameMonth.slice(0, max_month_click)
              this.websiteClick = this.initializedData.slice(0, max_month_click)
              for(let i = 0; i < websiteClick.length; i++){
                this.$set(this.websiteClick,websiteClick[i].month - 1,websiteClick[i].web_contact);
              }
              this.initChart('basic_dashboard3','Website Inquiry',this.websiteClick, fullNameMonth_click, abbrNameMonth_click)
            } else {
              this.initChart('basic_dashboard3','Website Inquiry',[], this.fullNameMonth, this.abbrNameMonth)
            }

            let callCounts = res.data.call_count
            if(callCounts.length > 0) {
              callCounts.sort((a, b) => {
                return b.month - a.month; // order desc
              })
              let max_month_counts = callCounts[0].month
              let fullNameMonth_counts = this.fullNameMonth.slice(0, max_month_counts)
              this.initBarGroup1('basic_dashboard2','Call Analytics',callCounts, fullNameMonth_counts)
              this.initBarGroup2('basic_dashboard5','New Clients by Referral Source',callCounts, fullNameMonth_counts)
            } else {
              this.initBarGroup1('basic_dashboard2','Call Analytics',[], this.abbrNameMonth)
              this.initBarGroup2('basic_dashboard5','New Clients by Referral Source',[], this.abbrNameMonth)
            }


            let call_weekly_count = res.data.call_weekly_count
            for(let i = 0; i < call_weekly_count.length; i++){
              for(let k = 0; k < this.weekList.length; k++){
                if(this.weekList[k]==call_weekly_count[i].week.toUpperCase()){
                  call_weekly_count[i].orderIndex = k
                }
              }
            }
            if(call_weekly_count.length > 0){
              call_weekly_count.sort((a, b) => {
                return a.orderIndex - b.orderIndex; // order desc
              })
            }
            if(call_weekly_count.length > 0) {
              let datas = []
              let rowNames = []
              for(let i = 0; i < call_weekly_count.length; i++){
                datas.push(call_weekly_count[i].call_count)
                rowNames.push(call_weekly_count[i].week)

              }
              this.initBar('basic_dashboard4','Call Weekly',datas, rowNames)
            } else {
              this.initBar('basic_dashboard4','Call Weekly',[], this.weekList)
            }
          }
        })
      },
      initChart(id,title,list, fullName, sortName){
        var chartDom = document.getElementById(id);
        this.myChart = this.$echarts.init(chartDom);
        var option = {
          title:{
            left: 'center',
            text: title
          },
          color: [
            '#5856D6',
          ],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            formatter:function(params){
              const monStr = fullName
              return `${monStr[params[0].dataIndex]} : ${params[0].data}`
            }
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '15%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: sortName
            }
          ],
          yAxis: [
            {
              max: function (value) {
                return value.max < 50 ? 50 : value.max;
              },
              type: 'value'
            }
          ],
          series: [
            {
              name: title,
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              data: list
            },
            // {
            //   type: 'line',
            //   name:'Targeted',
            //   markLine: {
            //     symbol: 'none',
            //     data: [
            //       {
            //         yAxis: 20,
            //         label: {
            //           show: false,
            //         },
            //         lineStyle: {
            //           type: 'solid',
            //           width: 2,
            //           color: '#F56565',
            //         },
            //       }
            //     ],
            //   }
            // }
          ]
        };
        this.myChart.setOption(option)
        this.loading = false
      },
      initBarGroup1(id,title,list, fullName){
        let avg_satisfaction = []
        let new_client = []
        let call_count = []
        for(let i = 0; i < list.length; i++){
          avg_satisfaction.push(list[i].avg_satisfaction)
          new_client.push(list[i].new_client)
          call_count.push(list[i].call_count)
        }
        var chartDom = document.getElementById(id);
        this.myChart = this.$echarts.init(chartDom);
        var option = {
          title:{
            left: 'center',
            text: title
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '15%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          xAxis: [
            {
              type: 'category',
              data: fullName
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Avg Satisfaction',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              data: avg_satisfaction
            },
            {
              name: 'New Client',
              type: 'line',
              emphasis: {
                focus: 'series'
              },
              data: new_client
            },
            {
              name: 'Call Count',
              type: 'line',
              stack: 'referral',
              emphasis: {
                focus: 'series'
              },
              data: call_count
            }
          ]
        };
        this.myChart.setOption(option)
        this.loading = false
      },
      initBarGroup2(id,title,list, fullName){
        let etc = []
        let facebook = []
        let google = []
        let instagram = []
        let linkedin = []
        let website = []
        let yelp = []
        for(let i = 0; i < list.length; i++){
          etc.push(list[i].referral.etc)
          facebook.push(list[i].referral.facebook)
          google.push(list[i].referral.google)
          instagram.push(list[i].referral.instagram)
          linkedin.push(list[i].referral.linkedin)
          website.push(list[i].referral.website)
          yelp.push(list[i].referral.yelp)
        }
        var chartDom = document.getElementById(id);
        this.myChart = this.$echarts.init(chartDom);
        var option = {
          title:{
            left: 'center',
            text: title
          },
          grid: {
            left: '4%',
            right: '4%',
            bottom: '15%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          xAxis: [
            {
              type: 'category',
              data: fullName
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: 'Etc',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: etc
            },
            {
              name: 'Facebook',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: facebook
            },
            {
              name: 'Google',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: google
            },
            {
              name: 'Instagram',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: instagram
            },
            {
              name: 'Linkedin',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: linkedin
            },
            {
              name: 'Website',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: website
            },
            {
              name: 'Yelp',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              data: yelp
            }
          ]
        };
        this.myChart.setOption(option)
        this.loading = false
      },

      initBar(id,title,list, fullName){
        var chartDom = document.getElementById(id);
        this.myChart = this.$echarts.init(chartDom);
        var option = {
          title:{
            left: 'center',
            text: title
          },
          color: [
            '#5856D6',
          ],
          grid: {
            left: '4%',
            right: '4%',
            bottom: '15%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            }
          },
          xAxis: [
            {
              type: 'category',
              data: fullName
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: [
            {
              name: title,
              type: 'bar',
              barWidth: 40,
              showBackground: true,
              itemStyle: {
                borderRadius: 20,
              },
              backgroundStyle: {
                borderRadius: 20,
                color: 'rgba(0, 0, 255, 0.1)'
              },
              emphasis: {
                focus: 'series'
              },
              data: list
            }
          ]
        };
        this.myChart.setOption(option)
        this.loading = false
      },
      /* handleDownload() {
        const img = new Image()
        let mapChart = this.$echarts.init(document.getElementById("basic_dashboard"))
        img.src = mapChart.getDataURL({
          type: "png",
          pixelRatio: 1,
          backgroundColor: "#fff",
        })
        img.onload = () => {
          const canvas = document.createElement("canvas")
          canvas.width = img.width
          canvas.height = img.height
          const ctx = canvas.getContext("2d")
          if (ctx) {
            ctx.drawImage(img, 0, 0, img.width, img.height)
            const link = document.createElement("a")
            link.download = `Monthly Analytics-${this.year}.png`
            link.href = canvas.toDataURL("image/png", 0.9)
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
        }
      }, */
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  #home .div{background-color: #ffffff;padding: 20px;display: flex;flex-direction: column;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  #home .div .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  #home .div .title span{height:32px;padding:0 20px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;}
  #basic_dashboard1{height:450px;}
  #basic_dashboard2{height:450px;}
  #basic_dashboard3{height:450px;}
  #basic_dashboard4{height:450px;}
  #basic_dashboard5{height:450px;grid-column:1 / span 2;}
  .grid{display: grid;grid-template-columns: 1fr 1fr;grid-template-rows: auto;}
  .time{display: flex;justify-content: flex-end;align-items: center;margin-top:30px;color:#5856D6;font-size: 14px;font-weight: bold;}
  .time span{border:1px solid #5856D6;height:20px;width:20px;border-radius: 5px;display: flex;justify-content: center;align-items: center;cursor: pointer;margin:0 14px;}
  .time span i{font-size:12px;font-weight: bold;}
  @media (max-width: 768px) {
    #basic_dashboard1{height:300px;}
    #basic_dashboard2{height:300px;}
    #basic_dashboard3{height:300px;}
    #basic_dashboard4{height:300px;}
    #basic_dashboard5{height:300px;grid-column:1;}
    .grid{grid-template-columns: 1fr;}
    .time{margin-top:10px;margin-bottom:20px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .grid{grid-template-columns: 1fr;}
  }
</style>
