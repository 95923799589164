<template>
  <div>
    <ul v-if="isArray">
      <li v-for="(item, index) in data" :key="index">
        <json-viewer :data="item"></json-viewer>
      </li>
    </ul>
    <dl style="padding-left: 20px;" v-else>
      <template v-for="(value, key) in data">
        <div :key="key" style="display: flex;align-items: flex-start;"><div style="flex-wrap: 0;margin-right: 10px;font-weight: bold;">{{ key }}:</div><span v-if="!isObject(value)">{{ value}}</span></div>
        <json-viewer :data="value" v-if="isObject(value)"></json-viewer>
      </template>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'JsonViewer',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    isArray() {
      return Array.isArray(this.data);
    }
  },
  methods: {
    isObject(value) {
      return value !== null && typeof value === 'object';
    }
  }
}
</script>

<style scoped>
dt {
  font-weight: bold;
}
dd {
  margin-left: 20px;
}
</style>
