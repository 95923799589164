<template>
  <div class="height-set">
    <!-- goodsList -->
    <div class="content mai">
      <h1>404</h1>
      <p>
        Invalid page, please return to the home page
      </p>
      <div class="button ok" @click="goHome">HOME</div>
      <hr/>
    </div>
    <div class="bottomDiv"></div>
  </div>
</template>

<script>
  import { putAction,getAction } from '@/network/manage'
  export default {
    name: 'Error404',
    data() {
      return {
        url: {
        },
        list:[],
      }
    },
    computed: {

    },
    created(){
    },
    methods:{
      goHome(){
        this.$router.push({path:'/home'})
      }
    }
  }
</script>

<style scoped>
.mai{display: flex;flex-direction: column;align-items: center;}
.mai .button{height:40px;background: #D0D0D0;border-radius: 2px;color:#ffffff;margin-top:50px;display: flex;justify-content: center;align-items: center;}
.ok{background: #5856d6 !important;cursor: pointer;}
.mai h1{font-size:20px;font-weight: normal;margin:40px 0;}
.mai p{font-size:15px;text-align: center;line-height:2;}
.mai hr{background-color: #F0F0F0;border: none;width:100%;height:1px;margin:60px 0;}
.goodsList .list{display: grid;}
.goodsList .til{font-size: 18px;text-align: center;margin-bottom:40px;}
.goodsList .list .item{background: #FFFFFF;box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);border-radius: 4px;overflow: hidden;display: flex;flex-direction: column;cursor: pointer;}
.goodsList .list .item .img{width:100%;background-position: center;background-size: cover;}
.goodsList .list .item h5{font-weight: normal;margin:10px 16px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.goodsList .list .item .price{padding:0px 16px 10px 16px;display: flex;align-items: center;}
.goodsList .list .item .price div{width:100%;display: flex;flex-direction: column;}
.goodsList .list .item .price h4{font-family: 'DMSans';line-height:1;}
.goodsList .list .item .price span{font-family: 'DMSans';line-height:1;}
.content{min-height: ;}
@media (max-width: 800px) {

  .height-set {min-height: calc(100vh - 60px - 252px)}
	.mai .button{width:100%;}
	.mai h1{font-size:18px;font-weight: normal;margin:40px 0;}
	.mai p{font-size:14px;text-align: center;line-height:2;}
	.mai hr{margin:40px 0;}
	.goodsList{display: flex;flex-direction: column;align-items: center;}
	.goodsList .til{margin-bottom:20px;font-size:16px;}
	.goodsList .list{grid-template-columns: 44.2vw 44.2vw;grid-row-gap: 20px;grid-column-gap: 16px;}
	.goodsList .list .item .img{width:44.2vw;height:24.25vw;}
	.goodsList .list .item h5{font-size:14px;height:40px;}
	.goodsList .list .item .price{padding: 0px 12px 10px 16px}
	.goodsList .list .item .price h4{font-size:15px;line-height: 1.5;}
	.goodsList .list .item .price span{font-size:12px;}
	.content{margin:0 16px;}
	.bottomDiv{height:100px;}
}
@media screen and (min-width: 800px) and (max-width: 1200px){
  .height-set {min-height: calc(100vh - 80px - 127px)}
	.content{width:800px;margin:auto;}
	.mai .button{width:400px;}
	.goodsList .list{grid-template-columns: 191px 191px 191px 191px;grid-row-gap: 20px;grid-column-gap: 12px;}
	.goodsList .list .item .img{width:191px;height:105px;}
	.goodsList .list .item .price i{width:30px;height:30px;}
	.goodsList .list .item h5{font-size:14px;height:40px;}
	.goodsList .list .item .price h4{font-size:14px;}
	.goodsList .list .item .price span{font-size:14px;}
	.bottomDiv{height:100px;}
}
@media(min-width: 1200px){
  .height-set {min-height: calc(100vh - 80px - 130px)}
	.content{width:1200px;margin:auto;}
	.mai .button{width:400px;}
	.goodsList .list{grid-template-columns: 285px 285px 285px 285px;grid-row-gap: 40px;grid-column-gap: 20px;}
	.goodsList .list .item .img{width:285px;height:156px;}
	.goodsList .list .item .price i{width:40px;height:40px;background-size: 60%;}
	.goodsList .list .item h5{height:48px;}
	.bottomDiv{height:200px;}
}
</style>
