<template>
  <div>
    <el-dialog title="Competitors" :width="dialogWidth" :visible="visible" :close-on-click-modal="false" @close="visible = false">
      <div class="form" v-loading="loading">
        <div class="addDiv">
          <el-button style="background-color:#5856D6;color:#ffffff;" @click="addPage()">Add</el-button>
        </div>
        <div class="list">
          <div class="item" v-for="item,index in list" :key="index">
            <div>
              {{item.name}}
              <a :href="item.google_url" target="_blank">{{item.address}}</a>
            </div>
            <i class="el-icon-delete" @click="deleteInfo(item.client_competitors_id)" style="color: #5856D6;cursor: pointer;font-size: 20px;flex-shrink: 0;"></i>
          </div>
        </div>
        <div style="display: flex;justify-content:flex-end;margin-top:20px;">
        <el-button style="background-color:#5856D6;color:#ffffff;" @click="confirmPage()"><i class="el-icon-check"></i> Confirm</el-button>
        </div>
      </div>
    </el-dialog>
    <add ref="addForm" @ok="getDate"></add>
  </div>
</template>

<script>
import Vue from 'vue';
import { getActionApi,deleteActionApi } from '@/network/manageApi';
import Add from './add'
export default {
  name: 'List',
  components: {Add},
  data() {
    return {
      dialogWidth:'600px',
      visible: false,
      loading: false,
      list: []
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  computed: {

  },
  created() {
    this.setDialogWidth()
  },
  methods: {
    init() {
      this.visible = true
      this.getDate()
    },
    addPage(){
      this.$refs.addForm.init()
    },
    confirmPage(){
      this.visible = false
      this.$emit('ok')
    },
    getDate(){
      this.loading = true
      getActionApi('/seo/competitor').then(res => {
        this.list = res.data
      }).catch((err) => {
        this.$message.error(err.response.data.details.data.message);
      }).finally((res) => {
        this.loading = false
      })
    },
    deleteInfo(id){
      const that = this
      this.$confirm('Do you really want to delete this records?', 'confirm', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning',
        center: true
      }).then(() => {
        that.loading = true
        deleteActionApi("/seo/competitor/"+id).then((res) => {
          that.$message({
            type: 'success',
            message: 'delete success!'
          });
          that.getDate()
        }).catch((err) => {
          that.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          that.loading = false
        })
      })
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 600 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  *{word-break:normal;}
  .form{}
  .list{display: grid;grid-template-columns: 1fr;grid-gap: 10px;}
  .list .item{display: flex;align-items: center;}
  .list .item img{width: 80px;height: 80px;background-color: #f7f7f7;margin-right: 10px;flex-shrink: 0;}
  .list .item div{display: flex;flex-direction: column;width: 100%;height: 80px;align-items: flex-start;}
  .list .item div a{text-decoration: underline;}
  .addDiv{display: flex;justify-content: flex-end;margin-bottom:20px;}
  @media (max-width: 768px) {

  }

</style>
