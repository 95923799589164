<template>
  <el-dialog title="QR Code" :width="dialogWidth" :visible.sync="visible" :close-on-click-modal="false">
    <div v-loading="form_loading">
      <div style="display: flex;align-items: center;justify-content: center;">
          <img :src="img_src" style="width: 400px;"/>
      </div>
      <div class="dialog-footer" style="display: flex;justify-content: center;">
        <el-button type="primary" size="small" @click="refresh" style="background-color: #5856D6;border-color:#5856D6;">Refresh</el-button>
      </div>
    </div>
  </el-dialog>

</template>

<script>
import Vue from 'vue';
import { postFileApi } from '@/network/manageApi';
import { mapGetters } from 'vuex'
export default {
  name: 'QrCode',
  components: {},
  data() {
    return {
      dialogWidth:'500px',
      visible: false,
      form_loading: false,
      img_src: null
    }
  },
  computed: {
    ...mapGetters(['nickname'])
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  created() {
    this.setDialogWidth()
  },
  methods: {
    init() {
      this.visible = true
      this.form_loading = true
      this.img_src = null
      postFileApi('/review/business-promoter/qr').then(res => {
        const blob = new Blob([res], { type: "image/*" })
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => {
          this.form_loading = false
          this.img_src = reader.result
        }
      })
    },
    refresh() {
      this.form_loading = true
      postFileApi('/review/business-promoter/qr').then(res => {
        const blob = new Blob([res], { type: "image/*" })
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => {
          this.form_loading = false
          this.img_src = reader.result
        }
      })
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 500 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  .form{display: flex;flex-direction: column;}
  .form .flex{display: grid;grid-template-columns:1fr 1fr;gap: 20px;padding-bottom: 0;}
  .form .div{display: flex;flex-direction: column;color:#A0ABC0;font-weight: bold;padding-bottom:20px;}
  .form .div input{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;}
  .form .div select{border-radius: 6px;border:1px solid #E2E8F0;height:40px;padding:0 20px;margin-top:10px;box-sizing: border-box;}
  .headImage{display: flex;width: 76px;margin-top:15px;}
  .headImage .edit{display: none;padding-bottom:0;width: 76px;height: 76px;position: absolute;justify-content: center;align-items: center;background-color: rgba(0, 0, 0, 0.5);border-radius: 4px;}
  .headImage .edit span{background-color: rgba(255, 255, 255, 0.36);margin-left:0;padding:4px 10px;border-radius:4px;font-weight: bold;color:#ffffff;cursor: pointer;}
  .headImage:hover .edit{display: flex;}
  .headImage img{width: 76px;height: 76px;border-radius: 4px;}
  .default_upload {width: 76px;height: 76px;border: 1px dashed #d9d9d9;border-radius: 6px;cursor: pointer;display: flex;align-items: center;justify-content: center;}
  .avatar-uploader-icon {font-size: 18px;color: #8c939d;}
  .font-size-16 {font-size: 16px;}
  .margin-top-switch {margin-top: 10px;}
</style>
