<template>
  <el-dialog title="Search Results" :width="dialogWidth" :visible="visible" :close-on-click-modal="false" @close="visible = false">
    <div class="form">
      <div class="titleInfo">
        <div>
          <h2>My Ranking</h2>
          <h3 v-if="info.my_ranking != -1">{{info.my_ranking}}</h3>
          <h3 v-else>20+</h3>
        </div>
        <div>
          <h2 v-if="info.competitor_lists">Competitors</h2>
          <div v-if="info.competitor_lists">
            <div class="div" v-for="item,index in info.competitor_lists" :key="index">
              <span>{{item.name}}</span>
              {{item.ranking==-1?'20+':item.ranking}}
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex;" v-if="type">
        <a :href="info.metadata.google_url"  target="_blank" style="background-color:#5856D6;color:#ffffff;margin-bottom:20px;padding:6px 20px;cursor: pointer;">snap shot</a>
      </div>
      <div class="list">
        <div class="item" v-for="item,index in info.business_results" :key="index">
          <img :src="item.photo"/>
          <div class="info">
            <p>{{index + 1}}. {{item.name}}</p>
            <a :href="item.place_url" target="_blank">{{item.address}}</a>
            <div class="div">
              <el-rate v-model="item.rating" disabled></el-rate>
              {{item.total_comments}}
            </div>
          </div>
          <span v-if="item.competitor" style="color: red;font-weight: bold;">!</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'ShowResult',
  components: {},
  data() {
    return {
      dialogWidth:'600px',
      visible: false,
      type:false,
      info: {}
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  computed: {

  },
  created() {
    this.setDialogWidth()
  },
  methods: {
    init(item,type) {
      this.visible = true
      this.info = item
      this.type = type
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 600 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  *{word-break:normal;}
  .form{}
  .div{background-color: #ffffff;display: grid;gap: 20px;border-radius: 16px;}
  .list{display: grid;grid-gap: 14px;grid-template-columns: 1fr;}
  .list .item{display: flex;}
  .list .item img{width: 80px;height:80px;object-fit: contain;margin-right: 20px;flex-shrink: 0;background-color: #f7f7f7;}
  .list .item .info{width: 100%;}
  .list .item .info p{color:#1A202C;margin:0;}
  .list .item .info a{text-decoration: underline;color:#1A202C;}
  .list .item .info .div{display: flex;margin-top:10px;}
  .titleInfo{display: grid;grid-template-columns: 1fr 1fr;padding-bottom:30px;}
  .titleInfo h2{color: #2D3748;font-size: 20px;margin-bottom: 10px;}
  .titleInfo h3{color: #5856D6;font-size: 20px;}
  .titleInfo .div{display: flex;align-items: center;color: #5856D6;font-size: 14px;}
  .titleInfo .div span{color: #2D3748;}
  @media (max-width: 768px) {

  }

</style>
