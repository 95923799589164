<template>
  <div id="home">

    <div class="content">
      <div class="title">
        Scan history
      </div>
      <el-table
        v-loading="table_loading"
        :data="tableData"
        @row-click="rowClick"
        :header-cell-style="{ fontSize: '14px', backgroundColor: '#F8F9FA',color:'#4A5468',fontWeight:'normal'}"
        :cell-style="{ fontSize: '14px'}"
        style="width: 100%;">
        <el-table-column
          align="center"
          min-width="120"
          label="Date">
          <template slot-scope="scope" v-if="scope.row.generated_datetime">
            {{moment(scope.row.generated_datetime).format('MM/DD/YY')}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="keywords"
          min-width="180"
          label="Keyword">
        </el-table-column>
        <el-table-column
          align="center"
          min-width="150"
          label="Grid">
          <template slot-scope="scope" v-if="scope.row.grid_number">
            {{scope.row.grid_number}} × {{scope.row.grid_number}}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="150"
          label="Diagonal">
          <template slot-scope="scope">
            {{scope.row.diagonal_km}} mile
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          min-width="150"
          label="Competitors">
          <template slot-scope="scope" v-if="scope.row.competitor_list != null && scope.row.competitor_list.length > 0">
            {{scope.row.competitor_list[0].name}} {{scope.row.competitor_list.length > 1 ? (' +' + (scope.row.competitor_list.length - 1)) : ''}}
          </template>
        </el-table-column>

        <el-table-column
          label="Action"
          align="center"
          min-width="100">
          <template slot-scope="scope">
            <el-link type="primary" title="Detail" @click.stop="show_edit(scope.row.client_map_grid_uuid,scope.row.competitor_list,scope.row)"><i class="font-size-16 el-icon-search"></i></el-link>
            <el-link type="primary" title="Detail" @click.stop="delete_info(scope.row.client_map_grid_uuid)" style="margin-left:10px;"><i class="font-size-16 el-icon-delete"></i></el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
          @current-change="handleCurrentChange"
          layout="prev, pager, next"
          :page-size="page_size"
          :total="totalRows">
        </el-pagination>
      </div>
      <show ref="viewForm"></show>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi,deleteActionApi } from '@/network/manageApi';
  import Show from './mapScan/show'
  import moment from 'moment/moment';
  export default {
    name: 'MapScanList',
    components: {
      Show
    },
    data() {
      return {
        totalRows: 0,
        current_page: 1,
        page_size: 10,
        tableData: [],
        table_loading: false,
        status_map: {"Answered":"Answered", "Missed call":"Missed call",
        "Busy": "Busy", "Unknown":"Unknown"}
      }
    },
    computed: {

    },
    mounted() {

    },
    created(){
      this.getDatas(1)
    },
    methods:{
      moment,
      show_edit(id,list,info) {
        console.log(info)
        this.$refs.viewForm.init(id,list)
      },
      rowClick(row){
        this.$refs.viewForm.init(row.client_map_grid_uuid,row.competitor_list)
      },
      getDatas(initSize) {
        if(initSize == 1) {
          this.current_page = 1
        } else {
          this.current_page = initSize
        }
        this.table_loading = true
        getActionApi("/seo/grid-map?size=" + this.page_size + "&page=" + this.current_page,{order:'datetime_desc'}).then((res) => {
          this.tableData = res.data
          this.totalRows = res.page.totalElements
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.table_loading = false
        })
      },
      delete_info(id){
        const that = this
        this.$confirm('Do you really want to delete this records?', 'confirm', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning',
          center: true
        }).then(() => {
          that.table_loading = true
          deleteActionApi("/seo/grid-map/"+id).then((res) => {
            that.$message({
              type: 'success',
              message: 'delete success!'
            });
            that.getDatas(1)
          }).catch((err) => {
            that.$message.error(err.response.data.details.data.message);
          }).finally((res) => {
            that.table_loading = false
          })

        })
      },
      handleCurrentChange(val) {
        this.getDatas(val)
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;word-break:normal;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .content{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;align-items: center;}
  .title div *{padding:10px 16px;font-size: 14px;}
  .pager{display: flex;justify-content: center;}
  .font-size-16 {font-size: 20px;}
  .margin-top-switch {margin-top: 10px;}

  .avatar-uploader-icon {font-size: 18px;color: #8c939d;}
  .headImageShow {width: 76px;height: 76px;border-radius: 4px;}
  @media (max-width: 768px) {
    .title{flex-direction: column;}
    .title div{margin-top:20px;}
    .center{display: flex;justify-content: center;}
    .content{padding:20px 5px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
