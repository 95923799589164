<template>
  <div class="header" v-if="type === 0" :class="!showMenu?'closed':''">
    <div class="div">
      <i class="menu el-icon-s-operation" @click="changeMenuShow"></i>
      <h1>{{menuTitle}}</h1>
    </div>
    <div class="div">
      <div style="margin-right: 10px;">
        <el-select v-model="value" filterable placeholder="Search here" @change="changeMenu">
          <el-option-group
            v-for="group in options"
            :key="group.label"
            :label="group.label">
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <p>Account ID# <b>{{nickname}}</b></p>
      <div class="userIcon pc">
        <div class="bg"><img v-if="headimgurl != ''" :src="getImg(headimgurl)"/><i v-else class="el-icon-user-solid"></i></div>
        <div class="userMenus">
          <div class="item" @click="go('/accountSetting')">
            <i class="el-icon-user"></i>
            Profile
          </div>
          <div class="item" @click="go('/resetPassword')">
            <i class="el-icon-key"></i>
            Reset Password
          </div>

          <div class="item" @click="exit">
            <i class="el-icon-lock"></i>
            Log out
          </div>
        </div>
      </div>

      <div class="userIcon mobile" @click="showMenuRight = !showMenuRight">
        <div class="bg"><img v-if="headimgurl != ''" :src="getImg(headimgurl)"/><i v-else class="el-icon-user-solid"></i></div>
        <div class="userMenus" :class="showMenuRight ? 'showMobileMenu' : ''">
          <div class="item" @click="go('/accountSetting')">
            <i class="el-icon-user"></i>
            Profile
          </div>
          <div class="item" @click="go('/resetPassword')">
            <i class="el-icon-key"></i>
            Reset Password
          </div>

          <div class="item" @click="exit">
            <i class="el-icon-lock"></i>
            Log out
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'PageHeader',
    props:{
      "type":{type:Number,default:0}
    },
    data() {
      return {
        menuTitle: '',
        value:'',
        showMenuRight:false,
        options: [{
          options: [{
            label: 'Dashboard',
            value: 'home'
          }]
        },{
          label: 'Inquiries',
          options: [{
            label: 'Call Inquiries',
            value: 'callInquiries'
          }, {
            label: 'Web Inquiries',
            value: 'webInquiries'
          }]
        }, {
          label: 'Reporting',
          options: [{
            label: 'Monthly Analytics',
            value: 'monthlyAnalytics'
          }, {
            label: 'Social Media',
            value: 'socialMedia'
          }, {
            label: 'SEO Report',
            value: 'seo'
          }]
        }, {
          label: 'Review',
          options: [{
            label: 'Review Management',
            value: 'reviewManagement'
          },
          {
            label: 'Business Promoter',
            value: 'businessPromoter'
          }]
        }, {
          options: [{
            label: 'Support',
            value: 'support'
          }]
        }
        , {
         label: 'Map Scan',
         options: [{
           label: 'Map Scan',
           value: 'mapScan'
         },
         {
           label: 'Scan history',
           value: 'mapScanList'
         }]
        }, {
         label: 'Payment',
         options: [{
           label: 'Bills',
           value: 'bills-pay'
         },
         {
           label: 'Payment History',
           value: 'paymentHistory'
         }]
        }
        ],
      }
    },
    computed: {
      ...mapGetters(['showMenu','showMenuMobile','nickname','headimgurl'])
    },
    watch: {
      '$route': {
        handler(newRoute) {
          if (!!newRoute.meta) {
            this.menuTitle = newRoute.meta.title
            if(!!newRoute.meta.childTitle)
              this.menuTitle = newRoute.meta.childTitle
          }
        },
        immediate: true,
      }
    },
    methods: {
      ...mapActions([ "Logout" ]),
      getImg(url){
        return `${process.env.VUE_APP_BASE_API}/public-file/public/`+url
      },
      changeMenuShow() {
        this.$store.commit('SET_SHOW_MENU_MOBILE', !this.showMenuMobile);
        this.$store.commit('SET_SHOW_MENU', !this.showMenu);
      },
      changeMenu(){
        this.$router.push('/'+this.value);
      },
      go(url){
        this.$router.push(url);
      },
      exit(){
        const that = this
        this.$confirm('Confirm to exit the current account?', 'warning', {
          confirmButtonText: 'ok',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          that.Logout({})
          .then(() => {
            this.$router.push({ path: '/login', query: { redirect: that.$route.fullPath } })
          }).catch((err) => {
            that.$message.error({
              title: 'error',
              description: err.message,
            })
          })
        })
      }
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .header{height:80px;display: flex;z-index:100;transition: all 0.2s linear;align-items: center;top:0;left:288px;position: fixed;width:calc(100vw - 288px);background-color:#F8F9FA;padding:0 20px;justify-content: space-between;border-bottom: 1px solid #CBD5E0;}
  .header .div{display: flex;align-items: center;}
  .header p{color:#717D96;font-size:12px;}
  .header p b{font-size:13px;}
  .header .menu{font-size:30px;color:#4A5468;margin:0 30px 0 10px;cursor: pointer;}
  .header h1{color:#2D3648;font-size: 20px;font-weight: normal;}
  .borderInput{border:1px solid #EDF0F7;border-radius: 12px;overflow: hidden;background-color: #ffffff;margin-right:16px;}
  .borderInput input{border: none;width:100px;text-align: center;height:40px;font-size: 14px;margin-left:16px;}
  .borderInput i{font-size: 18px;margin: 0 10px;color: #718096;}
  .userIcon{cursor: pointer;border-radius: 50%;margin-left: 16px;overflow: hidden;flex-shrink: 0;}
  .userIcon .bg{background-color: #A0AEC0;width: 32px;height:32px;display: flex;align-items: center;justify-content: center;color:#ffffff;}
  .userIcon .bg img{width: 32px;height:32px;}
  .userIcon i{font-size: 20px;}
  .userMenus{position: fixed;color:#4A5568;width: 164px;font-size:14px;box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);display: none;flex-direction: column;background-color: #ffffff;border-radius: 6px;padding:6px;right:20px;top:55px;}
  .userMenus .item{height: 34px;display: flex;align-items: center;padding-left:10px;}
  .userMenus .item:hover{background-color: rgba(0, 0, 0, 0.04);border-radius: 6px;}
  .userMenus .item i{margin-right: 10px;font-size: 16px;font-weight: bold;}
  .closed{left:120px;width:calc(100vw - 120px);}
  @media (max-width: 768px) {
    .header{flex-direction: column;padding:20px;left:0;height:140px;width:100vw;}
    .header .div{display: flex;justify-content: space-between;height:40px;width: 100%;}
    .header .div:nth-child(1){margin-bottom:20px;flex-direction: row-reverse;}
    .header .menu{font-size:30px;color:#4A5468;margin:0;}
    .showMobileMenu{display:flex !important;top:120px;}
    .pc{display: none;}
    .mobile{display: flex;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .header{left:120px;width:calc(100vw - 120px);}
    .showMobileMenu{display:flex !important;top:55px;}
    .pc{display: none;}
    .mobile{display: flex;}
  }
  @media screen and (min-width: 1025px) {
    .userIcon:hover .userMenus{display: flex;}
    .mobile{display: none;}
  }
</style>
