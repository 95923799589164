<template>
  <el-dialog title="Add" :width="dialogWidth" :visible="visible" :close-on-click-modal="false" @close="visible = false">
    <div class="form" v-loading="loading">
      <el-input
        placeholder="Search Keyword"
        prefix-icon="el-icon-search"
        @change="searchFunciton"
        v-model="keyword">
      </el-input>
      <div class="main">
        <div class="list">
          <el-empty description="No information found" v-if="shopList.length == 0"></el-empty>
          <div class="item" v-for="item,index in shopList" :key="index" @click="selectPlace(item)">
            <i class="el-icon-success" style="font-size: 20px;color: #5856D6;margin-right: 10px;" v-if="checkItem.id == item.id"></i>
            <i class="el-icon-success" style="font-size: 20px;color: #f7f7f7;margin-right: 10px;" v-else></i>
            <div>
              {{item.displayName.text}}
              <a :href="item.googleMapsUri" target="_blank">{{item.formattedAddress}}</a>
              <p>reviews: {{item.userRatingCount}}</p>
            </div>
          </div>
        </div>
        <div class="mapDiv">
          <GmapMap
            :center="mapCenter"
            :zoom="zoom"
            ref="mapRef"
            style="width: 100%; height: 450px;"
          >
            <GmapMarker v-if="checkItem && checkItem.location"
              :position="{lat:checkItem.location.latitude,lng:checkItem.location.longitude}"
              :clickable="true"
              :draggable="true"
            />
          </GmapMap>
          <div>
            <div class="imgBg" @click="centerMap">
              <img src="../../assets/imgs/backCenter.png"/>
            </div>
          </div>
        </div>
      </div>
      <el-button style="background-color:#5856D6;color:#ffffff;margin-top:20px;" :disabled="!checkItem.id" @click="addPage()">Add</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
import { getActionApi, postActionApi } from '@/network/manageApi';
export default {
  name: 'Add',
  components: {},
  data() {
    return {
      dialogWidth:'900px',
      visible: false,
      loading: false,
      mapCenter:{lat: 0, lng: 0},
      backCenter:{},
      shopList:[],
      zoom:16,
      checkItem:{},
      keyword:''
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  computed: {

  },
  created() {
    this.setDialogWidth()
  },
  methods: {
    init() {
      this.visible = true
      this.checkItem = {}
      this.shopList = []
      this.keyword = ''
      getActionApi('/user-management/my-information/googleplace').then(res => {
        this.$set(this.mapCenter,'lat',res.data.location.latitude)
        this.$set(this.mapCenter,'lng',res.data.location.longitude)
        this.backCenter = {...this.mapCenter}
      }).catch((err) => {
        this.$message.error(err.response.data.details.data.message);
      }).finally((res) => {
      })
    },
    addPage(){
      this.loading = true
      const param = {
        google_id:this.checkItem.id,
        google_url:this.checkItem.googleMapsUri,
        name:this.checkItem.displayName.text,
        address:this.checkItem.formattedAddress
      }
      postActionApi('/seo/competitor',param).then(res => {
        this.$message({
          message: 'add success!',
          type: 'success'
        });
        this.visible = false
        this.$emit('ok')
      }).catch((err) => {
        this.$message.error(err.response.data.details.data.message);
      }).finally((res) => {
        this.loading = false
      })
    },
    centerMap() {
      this.$refs.mapRef.panTo(this.backCenter);
    },
    selectPlace(item){
      this.checkItem = item
      this.$set(this.mapCenter,'lat',item.location.latitude)
      this.$set(this.mapCenter,'lng',item.location.longitude)
      this.backCenter = {...this.mapCenter}
      this.zoom = 16
    },
    searchFunciton(){
      this.shopList = []
      this.checkItem = {}
      if(this.keyword != ''){
        getActionApi('/seo/search/google-location?query='+this.keyword).then(res => {
          if(res.data.places)
            this.shopList = res.data.places
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
        })
      }
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 900 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  *{word-break:normal;}
  .form{display: flex;align-items: flex-end;flex-direction: column;}
  .addDiv{display: flex;justify-content: flex-end;margin-bottom:20px;}
  .main{display: flex;margin-top:10px;align-items: flex-start;width: 100%;}
  .list{display: grid;grid-template-columns: 1fr;grid-gap: 10px;width: 100%;max-height:450px;overflow-y: auto;}
  .list .item{display: flex;align-items: center;cursor: pointer;}
  .list .item img{width: 80px;height: 80px;background-color: #f7f7f7;margin-right: 10px;flex-shrink: 0;}
  .list .item div{display: flex;flex-direction: column;width: 100%;height: 80px;border-bottom:1px solid #f5f5f5;align-items: flex-start;margin-left:10px;}
  .list .item div a{text-decoration: underline;}
  .mapDiv{display: flex;align-items: flex-end;width: 100%;}
  .imgBg{position: absolute;width:40px;height:40px;margin-top:-80px;border-radius:50%;margin-left:-50px;background-color: #ffffff;display: flex;justify-content: center;align-items: center;box-shadow: 0 0 2px 1.5px rgba(0, 0, 0, 0.1);cursor: pointer;}
  .mapDiv img{width: 20px;}
  @media (max-width: 768px) {
    .main{flex-direction: column-reverse;}
    .list{display: flex;flex-direction: column;padding:20px 0;}
  }

</style>
