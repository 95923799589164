<template>
  <el-dialog title="Conmments" :width="dialogWidth" :visible="visible" :close-on-click-modal="false" @close="close_win">
    <div class="form">
      <swiper class="imgs" :options="swiperOption" ref="mySwiper">
        <swiper-slide class="item" v-for="(item,index) in info.detailResources" :key="index">
        <!-- <img :src=""/> -->
          <div class="imgItem" v-if="item.type == 'img'" :style="'background-image: url('+item.url+');'"></div>
          <div class="imgItem" v-else>
            <video width="100%" height="100%" controls ref="videoPlayerGoods">
              <source :src="item.url" type="video/mp4">
            </video>
          </div>

        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <div class="right">
        <div class="head">
          <p>{{info.time_date}}<span>{{info.time}}</span></p>
          <div>{{info.post_description}}</div>
        </div>
        <div class="list">
          <div class="item" v-for="item,index in list" :key="index">
            <div class="comment">
              <img :src="item.user_profile_image"/>
              <div>
                {{item.user_name}}
                <p>{{item.message}}</p>
              </div>
              <span>
                {{formatDatetime(item.created_datetime)}}
                <b>{{item.like_count}} likes</b>
              </span>
            </div>
            <!-- <div class="comment reply" v-for="itemChild,indexChild in item.list" :key="indexChild" v-if="item.list && item.list.length > 0">
              <img :src="itemChild.commenter_profile_url"/>
              <div>
                {{itemChild.commenter_name}}
                <p>{{itemChild.contents}}</p>
              </div>
              <span>
                {{formatDatetime(itemChild.created_datetime)}}
              </span>
            </div> -->
          </div>
          <div class="loading" v-loading="form_loading" element-loading-text="Loading..." v-if="form_loading"></div>
          <div class="more-div" v-if="!form_loading && next_token">
            <a class="more" @click="next_datas()">More+</a>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Vue from 'vue';
import { getActionApi } from '@/network/manageApi';
import moment from 'moment/moment';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'
export default {
  name: 'FbShow',
  components: {swiper, swiperSlide},
  data() {
    return {
      dialogWidth:'1040px',
      value:5,
      list:[],
      visible: false,
      info: {},
      swiperOption:{
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      form_loading: false,
      next_token: null
    }
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  computed: {

  },
  created() {
    this.setDialogWidth()
  },
  methods: {
    moment,
    close_win() {
      this.visible = false
      this.info = {}
    },
    formatDatetime(time){
      if(!time) {
        return ""
      }
      let momentTime = moment(time)
      // let convertedTime = momentTime.tz("America/New_York")
      return momentTime.format("MMM DD,yyyy hh:mm A")
    },
    init(item) {
      this.list = []
      this.visible = true
      this.form_loading = true
      this.info = item
      getActionApi('/socialmedia/facebook/contents/' + item.facebook_content_key + "/comments").then((res) => {
        this.list = res.data.data
        this.next_token = res.data.next_token
      }).catch((err) => {
      }).finally((res) => {
        this.form_loading = false
      })
    },
    next_datas() {
      this.form_loading = true
      getActionApi('/socialmedia/facebook/contents/' + this.info.facebook_content_key + "/comments?next_token=" + this.next_token).then((res) => {
        this.list.push(...res.data.data)
        this.next_token = res.data.next_token
      }).catch((err) => {
      }).finally((res) => {
        this.form_loading = false
      })
    },
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 1040 // default width
      if (val < def) {
        this.dialogWidth = '100%'
      } else {
        this.dialogWidth = def + 'px'
      }
    }
  }
}
</script>

<style scoped>
  .form{display: grid;grid-template-columns:1fr 1fr;grid-column-gap: 10px;}
  .imgs{width: 500px;height: 500px;}
  .imgItem{width: 500px;height: 500px;background-size: cover;background-position: center;}
  .swiper-button-prev, .swiper-button-next{transform: scale(0.7);}
  .right{width: 100%;display: flex;flex-direction: column;height: 500px;}
  .right .head{flex-shrink: 0;padding-bottom:10px;}
  .right .head span{color:#999999;margin-left:10px;}
  .right .list{display: flex;max-height: 100%;flex-direction: column;overflow-y: auto;padding:0 5px;padding-bottom:10px;min-height: 200px;}
  .comment{display: flex;align-items: flex-start;margin-top:10px;box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.1);padding:10px;border-radius: 10px;}
  .comment img{width: 50px;height: 50px;flex-shrink: 0;margin-right: 10px;background-color: #f7f7f7;border-radius: 4px;}
  .comment div{width: 100%;font-size: 13px;}
  .comment span{flex-shrink: 0;display: flex;flex-direction: column;align-items: flex-end;font-size: 12px;margin-left:10px;}
  .comment span b{font-size: 12px;font-weight: normal;margin:1px 0;}
  .comment span a{font-size: 12px;font-weight: normal;cursor: pointer;color: #5856D6;}
  .item{display: flex;flex-direction: column;}
  .reply{width: 86%;align-self: flex-end;padding:10px;}

  .loading {width: 100%;height: 30px;margin-top: 30px;}
  .more-div {display: flex;justify-content: center;align-items: center;padding: 20px 0 0 0;}
  .more-div .more {width: 60px;height: 32px;cursor: pointer;background-color: #5856D6;color: #ffffff;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 12px;}
  @media (max-width: 768px) {
     .form{display: flex;flex-direction: column;}
     .imgs{width: 90vw;height: 90vw;margin-bottom:20px;}
     .imgItem{width: 90vw;height: 90vw;}
     .right{width: auto;height: auto;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .form{display: flex;flex-direction: column;}
    .imgs{width:60vw;height: 60vw;margin-bottom:20px;}
    .imgItem{width: 60vw;height: 60vw;}
    .right{width: auto;height: auto;}
  }
</style>
