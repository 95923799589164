<template>
  <div id="home">

    <div class="div" v-loading="paymentDetailLoading">
      <div class="title" v-if="paymentDetail != null">
        Bills
        <div v-if="paymentDetail != null">
          Invoice
          <span style="font-size: 14px;border: 1px solid #E2E8F0;padding: 5px 15px;border-radius: 5px;">
            DITA-{{auto_complete_placeholder(5, paymentDetail.invoice_number)}}
            <span style="color:#34C759;margin-left: 20px;" v-if="paymentDetail.invoice_status == 'PAID'">{{translate_status(paymentDetail.invoice_status)}}</span>
            <span style="margin-left: 20px;" v-else>{{translate_status(paymentDetail.invoice_status)}}</span>
          </span>
        </div>
        <div v-if="paymentDetail != null">
          Due
          <span class="span">{{moment(paymentDetail.due_date).format("MMM D, yyyy")}}</span>
        </div>
        <span v-if="paymentDetail != null" class="btn" @click="downloadFunction" v-loading="downloadLoading">Download</span>
      </div>
      <div v-if="paymentDetail != null">
        <div class="payment" >
          Amount
          <div class="conp">
            ${{paymentDetail.total_amounts}}
            <div class="record">
              <p v-for="item,index in paymentDetail.items" :key="index">
                {{item.item_description}}
                <b>${{item.amounts}}</b>
              </p>
              <p>
                Total
                <b>${{paymentDetail.total_amounts}}</b>
              </p>
            </div>
          </div>
        </div>

        <div class="payment" v-if="paymentDetail != null && paymentDetail.invoice_status == 'PAID'">
          Paid on {{moment(paymentDetail.paid_datetime).format("MMM D, yyyy")}}
          <!-- <div class="conp">
            <div class="record">
              <p>
                {{translate_card_type(paymentDetail.payment_method.credit_card_type)}}
                <b>{{getCardNum(paymentDetail.payment_method.credit_card_number)}}</b>
              </p>
            </div>
          </div> -->
        </div>

      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import { getActionApi,downFileApi } from '@/network/manageApi';
  import moment from 'moment/moment';
  export default {
    name: 'BillsDetail',
    components: {
    },
    data() {
      return {
        value:'',
        paymentDetail:null,
        downloadLoading:false,
        paymentDetailLoading:false,
        status_map: {"OPEN":"Open", "OVERDUE":"Overdue",
        "PAID": "Paid", "CANCELED":"Canceled", "PARTIAL": "Partial"},
        card_type: {"VISA": "Visa card", "MASTERCARD": "Master card",
        "AMERICAN_EXPRESS": "American express",
        "DISCOVER": "Discover", "DINERS_CLUB": "Diners club",
        "JCB": "Jcb"}
      }
    },
    computed: {

    },
    mounted() {
      this.value = this.$route.query.uuid
      this.initLoadData()
    },
    created(){
    },
    methods:{
      translate_status(status) {
        if(status) {
          return this.status_map[status]
        }
        return;
      },
      translate_card_type(type) {
        if(type) {
          return this.card_type[type]
        }
        return;
      },
      auto_complete_placeholder(digit, number) {
        if(digit > number.toString().length) {
          return (this.completion_str((digit - number.toString().length), "0")) + number
        }
        return number.toString();
      },
      completion_str(digit, target) {
        let str = "";
        for(let i = 0;i < digit; i++) {
          str += target
        }
        return str;
      },
      moment,
      getCardNum(num){
        let newNum = ''
        if(num) {
            return "*** " + num.substring(num.length - 4)
        }
        return "";
      },
      getMonStr(mon){
        const monStr = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        return monStr[mon-1]
      },
      downloadFunction(){
        this.downloadLoading = true
        downFileApi('/payment/invoices/'+this.paymentDetail.client_invoice_uuid+'/download').then(res => {
          const url = window.URL.createObjectURL(new Blob([res]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download','invoices.pdf')
          document.body.appendChild(link)
          link.click()
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.downloadLoading = false
        })
      },
      initLoadData(){
        this.paymentDetailLoading = true
        getActionApi('/payment/invoices/' + this.value).then(res => {
          this.paymentDetail = res.data
        }).catch((err) => {
          this.$message.error(err.response.data.details.data.message);
        }).finally((res) => {
          this.paymentDetailLoading = false
        })
      },
    }
  }
</script>

<style scoped>
  body{overflow-x: hidden;}
  *{font-size:16px;font-size:inherit;box-sizing: border-box;}
  #home{background-color:#F8F9FA;padding:20px;display: grid;gap: 20px;}
  .div{background-color: #ffffff;padding: 30px;display: grid;gap: 20px;box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);border-radius: 16px;}
  .title{color:#2D3648;font-size: 20px;font-weight: bold;display: flex;justify-content: space-between;align-items: center;}
  .title div{display: flex;flex-direction: column;font-size: 16px;}
  .title div .span{color:#5856D6;font-size: 16px;}
  .torbar{display: flex;align-items: center;}
  .torbar div{width: 100%;display: flex;align-items: center;justify-content: center;height: 40px;font-size:14px;border:1px solid transparent;border-bottom:1px solid #CBD5E0;}
  .torbar .act{background-color: #ffffff;border-radius: 6px 6px 0 0;border: 1px solid #CBD5E0;border-bottom-color:#ffffff;color:#5856D6;}
  .torbar div:first-child{width: 240px;}
  .btn{height:40px;width:86px;cursor: pointer;background-color: #5856D6;color:#ffffff;border-radius: 6px;font-size: 14px;display: flex;align-items: center;justify-content: center;}
  .paymentDiv{display: grid;grid-template-columns:repeat(auto-fit,300px);grid-gap: 20px;margin:30px 0;}
  .paymentDiv .item{box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.06);padding:20px;border-radius: 10px;cursor: pointer;display:flex;align-items: flex-start;flex-direction: column;}
  .paymentDiv .item .info{width: 100%;display: flex;align-items: flex-start;}
  .paymentDiv .item .info .cadInfo{width: 100%;}
  .paymentDiv .item .delete{color:red;font-size: 14px;cursor: pointer;}
  .cadInfo{display: flex;align-items: center;margin-bottom: 10px;}
  .cadInfo .img{width: 50px;height: 30px;margin-right: 10px;object-fit: contain;}
  .cadInfo div{display: flex;flex-direction: column;font-size: 12px;line-height: 1;font-size: 14px;}
  .cadInfo div span{margin-top:4px;font-size:12px;color: #999999;}
  .paymentDiv .item input{width: 20px;height:20px;}
  .paymentDiv .options{display: flex;justify-content: space-between;align-items: center;width: 100%;font-size: 14px;color:gray;}
  .addBtn{cursor: pointer;background-color: #f7f7f7;font-size: 12px;color: #666666;border-radius: 4px;padding:6px 16px;}
  .payment{font-size: 20px;font-weight: bold;display: flex;flex-direction: column;}
  .payment .conp{padding:10px 20px;font-size: 20px;font-weight: bold;display: flex;flex-direction: column;}
  .payment .conp .record{padding:10px 20px;font-size: 14px;font-weight: normal;width: 500px;display: grid;grid-row-gap: 6px;}
  .payment .conp .record p{display: flex;justify-content: space-between;}
  .payment .conp .record p b{font-size: 16px;}
  .payment .conp .record p:last-child{border-top:1px solid #f7f7f7;padding-top:10px;}
  .payment .conp .record p:last-child b{font-size: 20px;}
  @media (max-width: 768px) {
    .title{flex-direction: column;margin:0 20px;}
    .title div{width: 100%;line-height: 2;}
    .div{padding:20px 5px;}
    .payment .conp .record{width: 100%;}
    .paymentDiv{grid-template-columns:1fr;margin:20px;}
    .torbar{margin:0 20px;}
    .payment{margin:0 20px;}
    .payment .conp{padding:10px 0;}
    .payment .conp .record{padding:10px 0;}
    .btn{align-self: flex-end;}
    .addBtn{margin-left:20px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {

  }
</style>
