<template>
  <div id="register">
    <el-input v-model="code" style="width: 500px;margin: 30px 0 0 30px;">
      <template slot="prepend">Code</template>
    </el-input>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import Vue from 'vue'



  export default {
    name: 'GoogleLogin',
    components: {
    },
    data() {
      return {
        code: null,
      }
    },
    mounted() {
    },
    computed: {

    },
    created() {
      this.code = this.$route.query.code
    },
    methods: {

    }
  }
</script>

<style scoped>

  * {
    font-family: "Helvetica Neue", Helvetica;
    font-size: 15px;
    font-variant: normal;
    padding: 0;
    margin: 0;
}

html {
    height: 100%;
}

body {
    background: #E6EBF1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
}

form {
    width: 530px;
    margin: 20px 0;
}

.group {
    background: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 20px;
}

label {
    position: relative;
    color: #8898AA;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    display: flex;
    flex-direction: row;
}

.group label:not(:last-child) {
    border-bottom: 1px solid #F0F5FA;
}

label>span {
    width: 80px;
    text-align: right;
    margin-right: 30px;
}

.field {
    background: transparent;
    font-weight: 300;
    color: #31325F;
    outline: none;
    flex: 1;
    padding-right: 10px;
    padding-left: 10px;
    cursor: text;
}

.field::-webkit-input-placeholder {
    color: #CFD7E0;
}

.field::-moz-placeholder {
    color: #CFD7E0;
}

button {
    float: left;
    display: block;
    background:#555ABF;
    color: white;
    box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10),
    0 3px 6px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 0;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    height: 40px;
    line-height: 38px;
    outline: none;
    cursor: pointer
}

button:focus {
    background: #555ABF;
}

button:active {
    background: #43458B;
}

button[disabled] {
    background:  #8898AA;
    cursor: default
}

.outcome {
    float: left;
    width: 100%;
    padding-top: 8px;
    min-height: 24px;
    text-align: center;
}

.success,
.error,
.loader {
    display: none;
    font-size: 13px;
}

.success.visible,
.error.visible {
    display: inline;
}

.loader.visible {
    display: block;
}

.error {
    color: #E4584C;
}

.success {
    color: #666EE8;
}

.success .token {
    font-weight: 500;
    font-size: 13px;
}


.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #666EE8; /* Blue */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 2s linear infinite;
    margin: 8px
}

.loader-small {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #666EE8; /* Blue */
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.input-box {
  margin-left: 12px;
  margin-right: 6px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 3px;
  border: 1px solid #ccc;
}
</style>
