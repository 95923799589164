<template>
  <div id="register">
    <div style="margin: 30px 0 0 30px;">Scope</div>
    <el-input
      type="textarea"
      :autosize="{ minRows: 2, maxRows: 4}"
      placeholder="Please enter scope"
      style="width: 600px;margin: 10px 0 0 30px;"
      v-model="scope_text">
    </el-input>
    <el-alert
        title="A space-delimited list of scopes that identify the resources that your application could access on the user's behalf."
        style="width: 600px;margin: 10px 0 0 30px;"
        type="info">
      </el-alert>
    <div style="margin: 30px 0 0 30px;">Access Type</div>
    <div style="margin: 10px 0 0 30px;">
      <el-radio-group v-model="access_type">
        <el-radio-button label="online">Online</el-radio-button>
        <el-radio-button label="offline">Offline</el-radio-button>
      </el-radio-group>
    </div>
    <div style="margin: 30px 0 0 30px;">Prompt</div>
    <div style="margin: 10px 0 0 30px;">
      <el-radio-group v-model="prompt">
        <el-radio-button label="none">None</el-radio-button>
        <el-radio-button label="consent">Consent</el-radio-button>
        <el-radio-button label="select_account">Select Account</el-radio-button>
      </el-radio-group>
    </div>

    <div>
        <el-button type="primary" style="width: 200px;margin: 30px 0 0 30px;" @click="go_google_login">Google Login</el-button>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import Vue from 'vue'



  export default {
    name: 'GoogleLogin',
    components: {
    },
    data() {
      return {
        scope_text: "profile email https://www.googleapis.com/auth/adsense https://www.googleapis.com/auth/analytics",
        access_type: 'offline',
        prompt: 'consent'
      }
    },
    mounted() {
    },
    computed: {

    },
    created() {
    },
    methods: {
      go_google_login() {
        let url = "https://accounts.google.com/o/oauth2/v2/auth?"
        let client_id_p = "client_id=201195855524-894mq5gb1iedfd3kda06ua4l4n3cktir.apps.googleusercontent.com"
        let redirect_uri = "&redirect_uri=https://ditans.405blvd.com/googleAuthorize"
        let response_type = "&response_type=code"
        let scope = "&scope="
        if(this.scope_text == null || this.scope_text == "") {
          scope = "&scope=profile email https://www.googleapis.com/auth/adsense https://www.googleapis.com/auth/analytics"
        } else {
          scope += this.scope_text
        }
        let access_type = "&access_type=" + this.access_type
        let prompt = "&prompt=" + this.prompt
        window.location.href = url + client_id_p + redirect_uri + response_type + scope + access_type + prompt
      }
    }
  }
</script>

<style scoped>

</style>
