<template>
  <div class="login" v-loading="loading">
    <div :class="show_type == 0?'bg':'bg_dental'">
      <img src="../assets/imgs/logo_white.png" v-if="show_type == 0"/>
      <img src="../assets/imgs/logo_dental.png" v-if="show_type == 1"/>
    </div>
    <div class="toolbar">
      <a class="acta" @click="type = 1">Find Password</a>
    </div>
    <div class="form">
      <span>Find by registered e-mail</span>
      <div class="div">
        <input placeholder="e-mail" v-model="email"/>
      </div>
      <div class="error" v-if="error_message != ''">{{error_message}}</div>
      <div class="btn" @click="sendEmail">Confirm</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import { postAction } from '@/network/manage'
  import Vue from 'vue'
import { putActionApi } from '@/network/manageApi'


  export default {
    name: 'Login',
    components: {
    },
    data() {
      return {
        type:0,
        email:'',
        loading:false,
        error_message: '',
        show_type: 0
      }
    },
    computed: {

    },
    created() {
      let domainName = window.location.hostname;
      if(domainName == "portal.lighthousedentalsolutions.com") {
        this.show_type = 1
      } else {
        this.show_type = 0
      }
    },
    methods: {
      sendEmail(){
        const testStr = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        if(this.email == ''){
          this.error_message = 'Email is required'
        }else if(!testStr.test(this.email)){
          this.error_message = 'Email format incorrect'
        }else{
          this.loading = true
          this.error_message = ''
          putActionApi('/user-management/my-information/password/find',{email:this.email}).then((res) => {
            this.$message({
              message: 'The password reset email has been sent to the email address',
              type: 'success'
            });
          }).catch((err) => {
            this.error_message = err.response.data.details.data.message
          }).finally((res) => {
            this.loading = false
          });
        }
      }
    }
  }
</script>

<style scoped>
  *{font-size:16px;font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  .login{display: flex;justify-content: center;align-items: center;height:100vh;flex-direction: column;}
  .bg{position: fixed;top:0;left:calc(50vw + 160px);z-index: -1;align-items: center;justify-content: center;border-radius: 0 0 0 10px;min-width:500px;width:calc(50vw - 160px);height:90vh;display: flex;background-image: url(../assets/imgs/login_bg.jpg);background-size:auto 100%;}
  .bg_dental{position: fixed;top:0;left:calc(50vw + 160px);z-index: -1;align-items: center;justify-content: center;border-radius: 0 0 0 10px;min-width:500px;width:calc(50vw - 160px);height:90vh;display: flex;background-image: url(../assets/imgs/background_dental.png);background-size: cover;}
  .bg img{height:94px;}
  .bg_dental img{height:94px;}
  .form{background-color: #ffffff;width:450px;box-shadow: 0px 6px 6px -1px rgba(0, 0, 0, 0.1);border-radius: 16px;padding:32px 42px;display: flex;flex-direction: column;}
  .form span{color:#A0AEC0;font-size:14px;margin-top:10px;margin-bottom:10px;}
  .form .div{border: 1px solid #CBD5E0;border-radius: 6px;overflow: hidden;padding:10px 14px;display: flex;align-items: center;}
  .form .act{border:1px solid #5856D6;}
  .toolbar{width:450px;display: flex;align-items: center;justify-content: center;}
  .toolbar a{cursor: pointer;font-size: 18px;line-height:52px;width: 160px;text-align: center;border-bottom:2px solid #ffffff;}
  .toolbar .acta{border-bottom:2px solid #5856D6;color:#5856D6;}
  .error{color:#F56565;text-align: center;margin-top:10px;font-size: 14px;}
  .form input{border:none;height:20px;font-size:14px;width: 100%;}
  .form .btn{height:52px;width:100%;background-color: #5856D6;cursor: pointer;color:#ffffff;font-size:17px;display: flex;align-items: center;justify-content: center;border-radius: 8px;margin:20px 0;}
  @media (max-width: 768px) {
    .bg{left:20px;width:calc(100vw - 40px);height:24vh;border-radius: 0 0 10px 10px;min-width:0;}
    .bg_dental{left:20px;width:calc(100vw - 40px);height:24vh;border-radius: 0 0 10px 10px;min-width:0;}
    .bg img{height:47px;}
    .bg_dental img{height:47px;}
    .toolbar{width:calc(100vw - 40px);}
    .form{width:calc(100vw - 40px);padding:20px 20px;border-radius: 10px;}
    .form h1{font-size:26px;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .bg{align-items: flex-start;justify-content: flex-start;height:80vh;}
    .bg_dental{align-items: flex-start;justify-content: flex-start;height:80vh;}
    .bg img{margin:30px;height:47px;}
    .bg_dental img{margin:30px;height:47px;}
  }
</style>
