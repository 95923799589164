<template>
  <div id="app" :class="type == 0?(!showMenu?'hclosed html':'html'):(!showMenu?'hclosed':'')">
    <page-header :type="type"></page-header>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else/>
    <page-menu :type="type"></page-menu>
  </div>
</template>

<script>
  import PageHeader from "components/PageHeader"
  import PageMenu from "components/PageMenu"
  import {isMobile} from "@/utils/utils"
  import { mapGetters } from 'vuex'
  export default {
    name: 'App',
    components: {
      PageHeader,
      PageMenu
    },
    data() {
      return {
        type: 0
      }
    },
    computed: {
      ...mapGetters(['showMenu']),
    },
    created() {
      let domainName = window.location.hostname;
      if(domainName == "portal.lighthousedentalsolutions.com") {
        document.title = "Lightouse Dental Solutions Portal"
        this.changeFavicon("/dt_logo.ico");
      } else {
        document.title = "Ditans Group Portal"
        this.changeFavicon("/logo.ico");
      }
    },
    watch:{
      '$route': {
        handler(newRoute) {
          const white_pattern = /^\/password-reset\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/
          const white_pattern_review = /^\/review\/business-promoter\/.*$/
          const white_pattern_nologin_pay = /^\/bills\/.*$/
          if(newRoute.path == '/login'
          || newRoute.path == '/findId'
          || white_pattern.test(newRoute.path)
          || white_pattern_review.test(newRoute.path)
          || white_pattern_nologin_pay.test(newRoute.path)
          || newRoute.path == '/404'
          || newRoute.path == '/') {
              this.type = 1
            } else {
              this.type = 0
            }
        },
        immediate: true,
      }
    },
    methods: {
      changeFavicon(src) {
        const link = document.querySelector("link[rel~='icon']");
        if (link) {
          link.href = src;
        } else {
          const newLink = document.createElement('link');
          newLink.rel = 'icon';
          newLink.href = src;
          document.getElementsByTagName('head')[0].appendChild(newLink);
        }
      }
    }
  }
</script>

<style>
	@import "./assets/css/base.css";
  .html{padding: 80px 0 0 288px;transition: all 0.2s linear;}
  .hclosed{padding: 80px 0 0 120px;}

  @media (max-width: 768px) {
    .html{padding: 140px 0 0 0;}
  }
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    .html{padding: 80px 0 0 120px;}
  }
</style>
